export const ORG_TOAST_PREFIXES = {
    EDIT: 'Организация успешно отредактирована',
    CREATE: 'Новая организация успешно создана'
}

export const ORG_FORM_TITLES = {
    EDIT: 'Редактирование организации',
    CREATE: 'Создание организации'
}

export const ORG_FIELD_NAMES = {
    FULL_NAME: 'fullName',
    SHORT_NAME: 'shortName',
    ABBREVIATION_NAME: 'abbreviationName',
    INN: 'inn',
    LEGAL_KPP: 'legalKpp',
    LEGAL_OGRN: 'legalOgrn',
    LEGAL_ADDRESS: 'legalAddress',
    LEGAL_OKPO: 'legalOkpo',
    LEGAL_OKVED: 'legalOkved',
    LEGAL_OKONH: 'legalOkonh',
    LEGAL_OKATO: 'legalOkato',
    LEGAL_OKOGU: 'legalOkogu',
    LEGAL_OKDP: 'legalOkdp',
    CONTACT_NAME: 'contactName',
    CONTACT_PHONE: 'contactPhone',
    CONTACT_EMAIL: 'contactEmail'
}

export const ORG_FIELDS = Object.values(ORG_FIELD_NAMES)

export const ORG_STATUSES = [
    { value: 'active', label: 'активная', bgColor: '#A1E6C3' },
    { value: 'inactive', label: 'архивная', bgColor: '#D7D7D7' },
    { value: 'new', label: 'новая', bgColor: '#FFBBB3' },
]
