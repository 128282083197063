<template>
    <div>
        <TreeTable
            :value="organizations"
            :scrollable='true'
            :paginator="true"
            :lazy="true"
            scroll-height='calc(100vh - 450px)'
            class="p-datatable-customers p-datatable-striped organization-list"
            :class="{ showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            selectionMode="single"
            :filters="filters"
            filterMode="lenient"
            resizable-columns
            @page="onPage"
            @sort="onSort"
            @node-expand="rowExpandHandler"
            @row-dblclick="rowDblClickHandler"
            @filter="filterAction"
            :contentStyle='{ overflow: "visible" }'
        >
            <!-- lazy mode options -->
            <!-- :lazy="true"
            @page="paginationChange($event)"
            @sort="onSort($event)" -->
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Список организаций</h5>
                    </div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center table-header-left">
                        <div v-if='showSendButton' class="p-p-0 p-send-btn">
                            <SplitButton
                                @click="mainSendButtonHandler"
                                class='custom-split-button'
                                label='Раздать форму'
                                :model='sendButtonItems'
                            />
<!--                            <Button-->
<!--                                @click="showPurposeFormsDialog"-->
<!--                                class="p-button"-->
<!--                                :disabled='!filteredOrgs'-->
<!--                            > Назначить форму </Button>-->
                        </div>
                        <div v-if="showLoadLicensesButton" class="p-p-0 p-new-btn">
                            <SplitButton class="custom-licenses-button" label="Импорт данных" :model="licensesItems" />
                        </div>
                    </div>
                    <div class="p-col-7 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText
                                type="text"
                                v-model="filters['name']"
                                placeholder="Поиск"
                                @keydown="selectedFilter"
                                @change="selectedFilter"
                            />
                        </span>
                            <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                        </div>
                        <div v-if='canAddOrg' class="p-p-0 p-new-btn">
                            <Button @click="$router.push('/organizations/new')" class="p-button"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Организации не найдены</template>
            <template #loading> Загрузка списка организаций. Пожалуйста подождите</template>
            <Column
                header="Название"
                header-class='org-header-name table-header'
                body-class='p-text-nowrap p-text-truncate org-body-name table-body'
                :sortable="true"
                field="publicName"
                sort-field="name.fullName"
                :expander='true'
                filterMatchMode='contains'
            >
                <template #body="slotProps">
                    <span :title="slotProps.node.data.publicName">{{ slotProps.node.data.publicName }}</span>
                </template>
                <template #filter v-show="showFilters">
                    <InputText
                        v-show="showFilters"
                        type="text"
                        v-model="filters['name']"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @keydown="selectedFilter"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Подчинение"
                header-class='org-header-subordinate table-header'
                body-class='p-text-nowrap p-text-truncate org-body-subordinate table-body'
                field="subordinateTo.name"
            >
                <template #body='slotProps'>
                    <span :title='slotProps.node.data.subordinateTo.name'>{{ slotProps.node.data.subordinateTo.name }}</span>
                </template>
                <template #filter v-show="showFilters">
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters['subordinateTo']"
                        :options="subordinations"
                        option-label="name"
                        placeholder="Поиск"
                        class="multiselect-custom"
                        filter
                        :showToggleAll="true"
                        data-key="id"
                        option-value="id"
                        display="chip-count"
                        @click.stop
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Расположение"
                header-class='org-header-location table-header'
                body-class='p-text-nowrap p-text-truncate org-body-location table-body'
                field="location.name"
            >
                <template #body='slotProps'>
                    <span :title='slotProps.node.data.location.name'>{{ slotProps.node.data.location.name }}</span>
                </template>
                <template #filter v-show="showFilters">
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters['location']"
                        :options="locations"
                        option-label="name"
                        placeholder="Поиск"
                        class="multiselect-custom"
                        filter
                        :showToggleAll="true"
                        data-key="id"
                        option-value="id"
                        display="chip-count"
                        @click.stop
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Округ"
                header-class='org-header-location table-header'
                body-class='p-text-nowrap p-text-truncate org-body-location table-body'
                field="district.name"
            >
                <template #body='slotProps'>
                    <span :title='slotProps.node.data.district.name'>{{ slotProps.node.data.district.name }}</span>
                </template>
                <template #filter v-show="showFilters">
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters['district']"
                        :options="locations"
                        option-label="name"
                        placeholder="Поиск"
                        class="multiselect-custom"
                        filter
                        :showToggleAll="true"
                        data-key="id"
                        option-value="id"
                        display="chip-count"
                        @click.stop
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Тип"
                header-class='org-header-type table-header'
                body-class='p-text-nowrap p-text-truncate org-body-type table-body'
                field="type.name"
            >
                <template #body='slotProps'>
                    <span :title='slotProps.node.data.type.name'>{{ slotProps.node.data.type.name }}</span>
                </template>
                <template #filter v-show="showFilters">
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters['type']"
                        :options="types"
                        option-label="name"
                        placeholder="Поиск"
                        class="multiselect-custom"
                        filter
                        :showToggleAll="true"
                        data-key="id"
                        option-value="id"
                        display="chip-count"
                        @click.stop
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Статус"
                header-class='org-header-status table-header'
                body-class='p-text-nowrap p-text-truncate org-body-status table-body'
                field="status"
            >
                <template #body='slotProps'>
                <span
                    :title='getStatus(slotProps).title'
                    :style='`background: ${getStatus(slotProps).bgColor}; padding: 4px; border-radius: 4px;`'
                >
                    {{ getStatus(slotProps).label }}
                </span>
                </template>
                <template #filter v-show="showFilters">
                    <Dropdown
                        v-show="showFilters"
                        v-model="filters.status"
                        :options="orgStatuses"
                        :showClear="true"
                        data-key="label"
                        option-value="value"
                        placeholder="Поиск"
                        option-label="label"
                        class="p-column-filter"
                        @change="selectedFilter"
                        @click.stop
                    />
                </template>
            </Column>
            <Column
                header=""
                header-class='p-text-center'
                body-style="position: relative; width: 52px; text-align: center;"
                header-style='width: 52px;'
            >
                <template #header>
                    <Button
                        icon="pi pi-filter"
                        :class="filterClasses"
                        class="p-button-rounded p-button-outlined filter-btn"
                        @click="showFilters = !showFilters"
                    />
                </template>
                <template #body="slotProps">
                    <template
                        v-if='getItemMenu(slotProps).length'
                    >
                        <div
                            class="p-panel-header-icon p-link"
                            @click.stop="toggleRowMenu($event, slotProps)"
                        >
                            <span class="pi pi-ellipsis-h"></span>
                        </div>
                        <Menu
                            class="redLastListElement"
                            :ref="`listMenu${slotProps.node.key}`"
                            :model="getItemMenu(slotProps)"
                            :popup="true"
                            :baseZIndex='10'
                        >
                            <template #item='{ item }'>
                                <MenuItem
                                    @click='item.command()'
                                    :item='item'
                                />
                            </template>
                        </Menu>
                    </template>
                </template>
                <!--            <template #body="slotProps">-->
                <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click="$router.push(`/organizations/${slotProps.data.id}`)" />-->
                <!--                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeOrganization(slotProps.data.id)" />-->
                <!--            </template>-->
            </Column>
            <template #paginatorLeft>
                <Button @click="downloadXLSX" label="Скачать XLSX" class="p-button p-component " />
            </template>
        </TreeTable>
        <loadLicensesDialog
            :showLoadLicensesDialog="showLoadLicensesDialog"
            @loadingChange="loadingChange"
        />
    </div>
</template>

<script>
import { getOrganizations, deleteOrganization, downloadOrganizations } from '@/api/organization';
import { getOrgsLocations } from '@/api/organization/orgsLocations';
import { getOrgsSubordinations } from '@/api/organization/orgsSubordinations';
import { getOrgsTypes } from '@/api/organization/orgsTypes';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { dataForTreeTable } from '@/main/utils/organizations';
import { ORG_STATUSES } from '@/constants/organizations';
import { SHOW_SEND_FORM_EVENT, USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';
import { ASSIGNMENT_DISTRIBUTION_MODES } from '@/constants/forms';
import { jsonApiListParser } from '@/main/utils/common';
import loadLicensesDialog from '@/components/organizations/dialogs/loadLicensesDialog.vue';

const { ASSIGNMENT, DISTRIBUTION } = ASSIGNMENT_DISTRIBUTION_MODES

const {
    organizationWriteAll,
    organizationWriteOwn,
    organizationWrite,
    organizationReadOwn,
    organizationReadAll,
    organizationRead,
    formWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'OrgsList',
    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    activated() {
        if (this.dataLoaded) this.$emit('loadingChange')
    },

    components: {
        loadLicensesDialog
    },

    data() {
        this.orgStatuses = ORG_STATUSES
        this.notTreeParsedOrgs = [];
        this.pageSize = DEFAULT_PAGE_SIZE;
        return {
            sendButtonItems: [
                {
                    label: 'Назначить форму',
                    command: () => {
                        this.openDialogHandler({
                            mode: ASSIGNMENT,
                            filters: this.filters,
                            orgs: this.selectedOrgs
                        })
                    }
                }
            ],
            licensesItems: [
                {
                    label: 'Реестр лицензий Росздравнадзора',
                    command: () => {
                        this.showLoadLicensesDialog = true;
                    },
                    permissionAccess: [ organizationWriteAll ]
                }
            ],
            filteredOrgsIds: [],
            showDeleteDialog: false,
            filters: { status: 'active' },
            // selectedOrgs: null,
            showFilters: false,
            dataLoaded: false,

            editOrgId: '',
            organizations: [],
            included: [],
            arrayLength: 5,
            new: [],
            locations: [],
            subordinations: [],
            types: [],
            currentPage: 1,
            totalRecords: null,
            filterInputTimeout: null,
            // TODO поправить статусы
            statuses: ['активная', 'неактивная'],
            orgTypes: ['Головная', 'Филиал'],
            showLoadLicensesDialog: false,
            showDelete: false,
            listMenuBranches: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem()
                    },
                    permissionAccess: [
                        organizationWriteAll,
                        organizationWriteOwn,
                        organizationWrite,
                        organizationReadOwn,
                        organizationReadAll,
                        organizationRead
                    ]
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        canAddOrg() {
            return [organizationWriteAll].some(p => this.userPermissionsObject[p])
        },
        canEditOrg() {
            return [
                organizationWriteOwn,
                organizationWriteAll,
                organizationWrite,
                organizationReadOwn,
                organizationReadAll,
                organizationRead
            ].some(p => this.userPermissionsObject[p])
        },
        filteredOrgs() {
            return Object.values(this.filters).some(i => !!i)
        },
        selectedOrgs() {
            const filteredOrgs = this.notTreeParsedOrgs.filter(({ id }) => this.filteredOrgsIds.includes(id))
            return filteredOrgs.length ? filteredOrgs : null
        },
        selectedOrg() {
            if (!this.editOrgId) return null
            return this.notTreeParsedOrgs.find(({ id }) => id === this.editOrgId)
        },

        showSendButton() {
            return [formWrite].some(p => this.userPermissionsObject[p])
        },

        showLoadLicensesButton() {
            return [ organizationWriteAll ].some(p => this.userPermissionsObject[p]);
        },

        listMenuItemsComputed() {
            return [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem()
                    },
                    permissionAccess: [
                        organizationWriteAll,
                        organizationWriteOwn,
                        organizationWrite,
                        organizationReadOwn,
                        organizationReadAll,
                        organizationRead
                    ]
                },
                {
                    label: 'Раздать форму',
                    icon: 'pi pi-angle-double-down',
                    command: () => {
                        this.openDialogHandler({
                            mode: DISTRIBUTION,
                            orgs: this.selectedOrg ? [this.selectedOrg] : null
                        })
                    },
                    permissionAccess: [ formWrite ]
                },
                {
                    label: 'Назначить форму',
                    icon: 'pi pi-angle-double-down',
                    command: () => {
                        this.openDialogHandler({
                            mode: ASSIGNMENT,
                            orgs: this.selectedOrg ? [this.selectedOrg] : null
                        })
                    },
                    permissionAccess: [ formWrite ]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: this.removeOrganization
                        });
                    },
                    class: 'remove-row',
                    visible: this.showDelete,
                }
            ];
        }
    },
    methods: {
        onPage(event) {
            setTimeout(() => {
                this.currentPage = event.page + 1;
                this.pageSize = event.rows;
                const sortParameter = event.sortField
                    ? `${ event.sortOrder > 0 ? '' : '-' }${ event.sortField }` : '';
                this.getAllOrganizations(true, sortParameter);
            }, 1000);
        },

        onSort(event) {
            setTimeout(() => {
                this.currentPage = 1;
                this.pageSize = DEFAULT_PAGE_SIZE;
                const sortParameter = event.sortField
                    ? `${ event.sortOrder > 0 ? '' : '-' }${ event.sortField }` : '';
                this.getAllOrganizations(true, sortParameter);
            }, 1000);
        },

        selectedFilter() {
            clearTimeout(this.filterInputTimeout);
            this.filterInputTimeout = setTimeout(() => {
                this.currentPage = 1;
                this.pageSize = DEFAULT_PAGE_SIZE;
                this.getAllOrganizations(true);
            }, 500);
        },

        getItemMenu(slotProps) {
            const isBranch = slotProps.node?.data?.__isBranch
            return this.accessibleItems(isBranch ? this.listMenuBranches : this.listMenuItemsComputed)
        },
        mainSendButtonHandler() {
            this.openDialogHandler({
                mode: DISTRIBUTION,
                filters: this.filters,
                orgs: this.selectedOrgs
            })
        },
        openDialogHandler(payload) {
            this.$root.$emit(SHOW_SEND_FORM_EVENT, payload)
        },
        filterAction(eventObj) {
            const { filteredValue } = eventObj
            this.filteredOrgsIds = this.filteredOrgs ? filteredValue.map(({ key }) => {
                return key
            }) : []
        },
        async showPurposeFormsDialog() {
            this.$root.$emit(SHOW_SEND_FORM_EVENT)
        },
        rowDblClickHandler(event) {
            const { node: { key, parentId } } = event
            this.editOrgId = key;
            this.parentId = parentId;
            this.editItem()
        },
        editItem() {
            if (!this.canEditOrg) return
            if (this.parentId) {
                this.$router.push(`/organizations/edit/${ this.editOrgId }/${ this.parentId }`);
            } else {
                this.$router.push(`/organizations/edit/${ this.editOrgId }`);
            }
        },
        getStatus (slotProps) {
            const status = this.orgStatuses.find(item =>  item.value === slotProps.node.data.status)
            return status || {}
        },
        async toggleRowMenu(event, slotProps) {
            const { node: { key, parentId, data } } = slotProps
            // Close previous popup
            if (key !== this.editOrgId) {
                this.$refs[`listMenu${this.editOrgId}`] && this.$refs[`listMenu${this.editOrgId}`].hide(event)
            }

            this.editOrgId = key
            this.parentId = parentId;
            this.showDelete = data.status === 'new';

            const menuEl = this.$refs[`listMenu${key}`]
            // Open new popup
            menuEl.toggle(event)
            await this.$nextTick()
            const { y } = event
            const { $el } = menuEl
            if ($el.style) $el.style.top = `${y}px`
        },
        async rowExpandHandler(node) {
            let lazyNode = {...node};

            const filter = {
                parentId: { $eq: node.key }
            };

            const { included, organizations } = await getOrganizations({ filter, relationShip: true });
            lazyNode.children = dataForTreeTable(organizations, included, node.key);

            lazyNode.children.map((organization) => {
                organization.parentId = node.key;
                return organization;
            })

            this.organizations = this.organizations.map(n => {
                if (n.key === node.key) {
                    n = lazyNode;
                }
                return n;
            });
        },

        async getAllOrganizations(relationShip, sortParametr = '') {
            this.$emit('loadingChange', true);
            try {
                let filter = {
                    parentId: null
                };

                if (this.filters.subordinateTo?.length) {
                    filter['subordinateToId'] = { '$in': this.filters.subordinateTo };
                }

                if (this.filters.location?.length) {
                    filter['locationId'] = { '$in': this.filters.location };
                }

                if (this.filters.district?.length) {
                    filter['districtId'] = { '$in': this.filters.district };
                }

                if (this.filters.type?.length) {
                    filter['typeId'] = { '$in': this.filters.type };
                }

                if (this.filters.status?.length) {
                    const today = new Date();
                    if (this.filters.status === 'new') {
                        filter['activeFrom'] = null;
                    }
                    if (this.filters.status === 'active') {
                        filter['activeFrom'] = { $lte: `${ today.toISOString().substring(0, 10) }T23:59:59Z` };
                        filter['activeUntil'] = null;
                    }
                    if (this.filters.status === 'inactive') {
                        filter['activeUntil'] = { $lte: `${ today.toISOString().substring(0, 10) }T00:00:00Z` };
                    }
                }

                if (this.filters.name?.length) {
                    filter = {
                        $and: [
                            filter,
                            {
                                $or: [
                                    { 'name.fullName': { '$ilike': this.filters.name } },
                                    { 'name.shortName': { '$ilike': this.filters.name } }
                                ]
                            }
                        ]
                    };
                }

                const { included, organizations, meta } = await getOrganizations({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    filter: filter,
                    relationShip,
                    sortParametr
                });

                this.included = included;
                this.organizations = dataForTreeTable(organizations, included);
                this.totalRecords = meta.pagination.total;
                this.notTreeParsedOrgs = jsonApiListParser(organizations, included);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
            await this.expandAll();
        },

        async expandAll() {
            for (let node of this.organizations) {
                await this.rowExpandHandler(node);
            }
        },

        async removeOrganization() {
            try {
                this.$emit('loadingChange', true);
                const response = await deleteOrganization(this.editOrgId);
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }
                this.organizations = this.organizations.filter((organization) => organization.key !== this.editOrgId);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        orgType(organization) {
            let orgType = 'Филиал';

            if (organization.relationships.parent.data === null) orgType = 'Головная';
            // if (organization.id == '66974f5e-f084-5b17-bd70-8e21f81fcee7') orgType = 'Филиал'; //Пример

            return orgType;
        },

        downloadXLSX() {
            let filter = {
                parentId: null
            };

            if (this.filters.subordinateTo?.length) {
                filter['subordinateToId'] = { '$in': this.filters.subordinateTo };
            }

            if (this.filters.location?.length) {
                filter['locationId'] = { '$in': this.filters.location };
            }

            if (this.filters.district?.length) {
                filter['districtId'] = { '$in': this.filters.district };
            }

            if (this.filters.type?.length) {
                filter['typeId'] = { '$in': this.filters.type };
            }

            if (this.filters.status?.length) {
                const today = new Date();
                if (this.filters.status === 'new') {
                    filter['activeFrom'] = null;
                }
                if (this.filters.status === 'active') {
                    filter['activeFrom'] = { $lte: `${ today.toISOString().substring(0, 10) }T23:59:59Z` };
                    filter['activeUntil'] = null;
                }
                if (this.filters.status === 'inactive') {
                    filter['activeUntil'] = { $lte: `${ today.toISOString().substring(0, 10) }T00:00:00Z` };
                }
            }

            if (this.filters.name?.length) {
                filter = {
                    $and: [
                        filter,
                        {
                            $or: [
                                { 'name.fullName': { '$ilike': this.filters.name } },
                                { 'name.shortName': { '$ilike': this.filters.name } }
                            ]
                        }
                    ]
                };
            }

            downloadOrganizations({ filter: filter });
        },

        loadingChange(payload = false) {
            this.$emit('loadingChange', payload);
        }
    },
    async created() {
        try {
            await Promise.all([
                getOrgsLocations().then(({ locations }) => {
                    this.locations = locations
                }),
                getOrgsSubordinations().then(({ subordinations }) => {
                    this.subordinations = subordinations;
                }),
                getOrgsTypes().then(({ types }) => {
                    this.types = types;
                }),
                this.getAllOrganizations(true),
            ]);
        } catch (error) {
            this.$requestError(error.message);
        } finally {
            this.$emit('loadingChange');
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect-custom {
    min-width: 98%;
    max-width: 98%;
    margin-top: 4px;

    .p-multiselect-label {
        color: #8C8C8C;
        font-size: 13px;
        grid-template-columns: none;
    }

    .p-multiselect-token {
        align-items: center;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        .p-multiselect-token-label {
            margin-right: 0.25rem;
            white-space: normal;
        }
    }

    .p-multiselect-trigger {
        width: 1.3rem;
    }

    .p-multiselect-item {
        width: 340px;
        min-width: 100%;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 1.2rem;
    }
}

.p-datatable-customers {
    ::v-deep {
        .table-header-left {
            margin-top: 12px;
            .p-send-btn {
                &, button {
                    height: 100%;
                    align-items: flex-end;
                }
            }
        }

        .p-treetable {
            &-header {
                background: #ffffff;
                border: unset;
                padding: 0 0 1rem 0;
            }
            &-thead {
                th.p-filter-column {
                    span {
                        line-height: unset;
                    }
                    &:hover {
                        background: unset!important;
                    }
                }
                & {
                    tr {
                        &:nth-child(2) {
                            height: unset;
                            max-height: 44px;
                        }
                        th {
                            background: #E0E0E0;
                            border: unset;
                            overflow: unset!important;
                            overflow-x: hidden;
                            &:first-child {
                                padding-left: 49px;
                            }
                        }
                    }
                }
            }
            &-tbody {
                tr {
                    height: 44px!important;
                    &:hover {
                        background: #a5dca8!important;
                    }
                    &:nth-child(even) {
                        background: #eaeaea!important;
                        &:hover {
                            background: #a5dca8!important;
                        }
                    }
                    td {
                        padding: 0 0 0 1rem;
                        &:last-child {
                            padding-right: 1rem;
                        }
                    }
                }
            }
        }

        .p-panel-header-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }
        }

        tr {

        }

        .org {
            &-header {
                &-subordinate {
                    width: calc(11.71875vw + 16.2px);
                }
                &-location {
                    width: calc(9.375vw + 16.2px);
                }
                &-type {
                    width: calc(17.96875vw + 16.2px);
                }
                &-status {
                    width: calc(6.25vw + 16.2px);
                }
            }
        }
    }
}

::v-deep {
    .form-list {
        &-dialog {
            width: 960px!important;
        }
    }
}
</style>
