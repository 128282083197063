export const dataForTreeTable = (items, included, parentId = null) => {
    const mainOrgs = items.filter(i => {
        if (i.relationships) {
            if (i.relationships.parent?.data) i.attributes.__isBranch = true
            if (!parentId) return !i.relationships.parent?.data;
            return i.relationships.parent?.data.id === parentId
        }
        return true
    })
    return mainOrgs.map((organization) => generateOrganization(organization, items, included));
}

export const formatDate = (value) => {
    if (!value) return '';
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    return new Date(value).toLocaleDateString('ru-RU', options);
}

function generateOrganization(organization, items, included) {
    const relationships = organization.relationships || {}
    // FIXME осторожно - хардкод!!!!
    const relationFields = (Object.keys(relationships).length && Object.keys(relationships)) || ['subordinateTo', 'location', 'type']
    relationFields.forEach(field => {
        const relationData = (relationships[field] && relationships[field].data)
        if (relationData && field !== 'parent') {
            const { id, type } = relationData
            const includedItem = included.find(i => i.type === type && i.id === id)
            if (includedItem) {
                organization.attributes[field] = { ...includedItem.attributes, id: includedItem.id }
            } else organization.attributes[field] = {}
        } else {
            organization.attributes[field] = {}
        }
    })
    const data = {
        ...organization.attributes,
        createdAt: formatDate(organization.attributes.createdAt)
    }
    let children = items.filter(i => {
        if (i.relationships) {
            const parentData = i.relationships.parent?.data
            if (!parentData) return false
            const { id } = parentData
            return organization.id === id
        }
        return false
    })

    if (children.length) {
        children = children.map(i => {
            return generateOrganization(i, items, included)
        })
    }
    // else {
    //     const firstProp = Object.keys(data)[0]
    //     children.push({
    //         data: {
    //             [firstProp]: 'Нет филиалов'
    //         }
    //     })
    // }
    return {
        key: organization.id,
        data,
        children
    }
}
