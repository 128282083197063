import { axios, apiUrl } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getOrgsTypes(page = 1, pageSize = PAGE_SIZE, sortParametr = '') {
  try {
    const response = await axios.get(`${apiUrl}/organization-type`, {
        params: {
            page,
            'page-size': pageSize,
            sort: sortParametr
        }
    });

    let data = response.data.data.map(org => {
      return {
        id: org.id,
        name: org.attributes.name,
        active: org.attributes.active
      }
    })
    return {
      types: data,
      included: response.data.included,
      meta: response.data.meta
    }
  } catch (error) {
    generateErrors(error)
  }
}

export async function createOrgsType(orgsType) {
  try {
    const response = await axios.post(`${apiUrl}/organization-type`, orgsType)

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active
    }

  } catch (error) {
    if (error.isAxiosError) {
      let status = error.response.status;
      switch (status) {
        case 404:
          throw new Error('Произошла ошибка 404. Мой текст');
      }
    }
    throw new Error(error.response.data.errors[0].meta.trace[0].message);
  }
}

export async function patchOrgsType(data, id) {
  if (!data) {
    throw new Error('Данные не пришли');
  }
  try {
    const response = await axios.patch(`${apiUrl}/organization-type/${id}`, data)

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active,
      loading: false,
    };
  } catch (error) {
    if (error.isAxiosError) {
      let status = error.response.status;
      switch (status) {
        case 404:
          throw new Error('Произошла ошибка 404. Мой текст');
      }
    }
    return error;
  }
}

