import { axios, apiUrl } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getOrgsLocations(page = 1, pageSize = PAGE_SIZE, sortParametr = '') {
  try {
    const response = await axios.get(`${apiUrl}/organization-location`, {
        params: {
            page,
            'page-size': pageSize,
            sort: sortParametr
        }
    });

    let data = response.data.data.map(location => {
      return {
        id: location.id,
        name: location.attributes.name,
        active: location.attributes.active
      }
    })

    return {
      locations: data,
      included: response.data.included,
      meta: response.data.meta
    }
  } catch (error) {
      generateErrors(error)
  }
}

export async function createOrgsLocation(orgsLocation) {
  try {
    const response = await axios.post(`${apiUrl}/organization-location`, orgsLocation)

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active
    }

  } catch (error) {
      generateErrors(error);
  }
}

export async function patchOrgsLocation(data, id) {
  if (!data) {
    throw new Error('Данные не пришли');
  }
  try {
    const response = await axios.patch(`${apiUrl}/organization-location/${id}`, data);

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active,
      loading: false,
    };
  } catch (error) {
      generateErrors(error);
  }
}
