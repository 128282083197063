import { axios, apiUrl } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getOrgsSubordinations(page = 1, pageSize = PAGE_SIZE, sortParametr = '') {
  try {
    const response = await axios.get(`${ apiUrl }/organization-structure`, {
        params: {
            page,
            'page-size': pageSize,
            sort: sortParametr,
        }
    });

    let data = response.data.data.map(subordination => {
      return {
        id: subordination.id,
        name: subordination.attributes.name,
        active: subordination.attributes.active
      }
    })
    return {
      subordinations: data,
      included: response.data.included,
      meta: response.data.meta
    }
  } catch (error) {
    generateErrors(error);
  }
}

export async function createOrgsSubordination(orgsSubordination) {
  try {
    const response = await axios.post(`${apiUrl}/organization-structure`, orgsSubordination)

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active
    }

  } catch (error) {
      generateErrors(error);
  }
}

export async function patchOrgsSubordination(data, id) {

  if (!data) {
    throw new Error('Данные не пришли');
  }
  try {
    const response = await axios.patch(`${apiUrl}/organization-structure/${id}`, data);

    return {
      id: response.data.data.id,
      name: response.data.data.attributes.name,
      active: response.data.data.attributes.active,
      loading: false,
    };
  } catch (error) {
      generateErrors(error);
  }
}
