<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            header=""
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="closeDialog"
        >
            <template #header>
                <h3 class="p-mb-0">Загрузить файл лицензий</h3>
            </template>
            <FileUpload class='p-mb-4' name='demo[]' :customUpload='true' :auto='true' @uploader='onUpload'
                        chooseLabel='Выберите файл' cancelLabel='Очистить' accept='.zip'>
                <template #empty>
                    <p>Перетащите сюда файл для загрузки</p>
                </template>
            </FileUpload>

            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button class='p-button' @click='uploadData' :disabled='!uploadFile'>
                        <span class='p-button-label'>Загрузить</span>
                    </Button>
                    <Button class='p-button p-button-outlined p-button-secondary' type='button' @click='closeDialog'>
                        <span class='p-button-label'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { loadLicensesOrganizations } from '@/api/organization';

export default {
    name: 'loadLicensesDialog',

    props: {
        showLoadLicensesDialog: {
            require: true,
            type: Boolean
        }
    },

    mixins: [ requestToastHandler ],

    emits: [ 'loadingChange' ],

    data() {
        return {
            uploadFile: null,
            showDialog: this.showLoadLicensesDialog,
            submitted: false,
        };
    },

    methods: {
        async uploadData() {
            this.submitted = true;

            if (!this.uploadFile) {
                return;
            }

            this.$emit('loadingChange', true);
            try {
                const result = await loadLicensesOrganizations(this.uploadFile);
                if (result.status === 204) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Запрос на загрузку данных поставлен в очередь, по завершению вам прийдёт уведомление.',
                        life: 3500,
                    });
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }

            this.closeDialog();
        },

        onUpload(event) {
            const fD = new FormData();
            fD.append('file', event.files[0]);
            this.uploadFile = fD;
        },

        closeDialog() {
            this.showDialog = false;
            this.uploadFile = null;
            this.submitted = false;
        },
    },

    watch: {
        showLoadLicensesDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showLoadLicensesDialog;
                }
            }
        }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }

    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.uploadInput {
    display: none;
}

.uploadwidth {
    width: 164px;
}

::v-deep .p-fileupload-buttonbar {
    padding: 10px;

    span:nth-child(1) {
        margin-right: 12px;
    }

    button:nth-child(2) {
        display: none !important;
    }

    .p-button {
        box-shadow: none;
    }
}

::v-deep .p-dialog {
    min-width: 400px;
}
</style>
